export const currentYear = new Date().getFullYear()
export const phoneNoReg = /^[0-9]{11}$/
export const emailReg =
  /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const introReviewSlideList = [
  {
    boldPc: '독학으로 디자인을 공부했지만, ',
    boldM: '독학으로 디자인을 공부했지만,\n',
    textPc: '멋사 부트캠프에서\n데이터 역량을 더해 국내 최고 에이전시에 취업했습니다.',
    textM: '멋사 부트캠프에서 데이터 역량을 더해\n국내 최고 에이전시에 취업했습니다.',
    author: 'UX/UI디자인 2기 수료생, 노OO',
  },
  {
    boldPc: '철학과를 졸업했지만, 멋사 부트캠프는\n',
    boldM: '철학과를 졸업했지만, 멋사 부트캠프는\n',
    textPc: '비전공자도 개발자로 취업할 수 있는 최고의 커리큘럼이였습니다.',
    textM: '비전공자도 개발자로 취업할 수 있는\n최고의 커리큘럼이였습니다.',
    author: '앱 Android 1기 수료생, 이OO',
  },
  {
    boldPc: '‘비전공자여서 배울 수 있는 기회가 부족했다’',
    boldM: '비전공자여서 기회가 부족',
    textPc: '생각하고 있다면\n저처럼 비전공자도 부트캠프로 취업할 수 있다고 말하고 싶습니다.',
    textM: '하다고\n생각하고 있다면 저처럼 비전공자도\n취업할 수 있다고 말하고 싶습니다.',
    author: 'UX/UI디자인 1기 수료생, 유OO',
  },
  {
    boldPc: '졸업을 앞두고 서류탈락만하던 포트폴리오',
    boldM: '졸업을 앞두고 서류탈락하던 포트폴리오',
    textPc: '를\n실무형 프로젝트를 경험하며 취업에 성공할 수 있었습니다.',
    textM: '를\n실무형 프로젝트로 다양한 회사의\n서류합격 소식을 받을 수 있었습니다.',
    author: '앱 Android 2기 수료생, 이OO',
  },
  {
    boldPc: '부트캠프를 통해 돈 주고도 사기 어려운 교육',
    boldM: '부트캠프를 통해 돈 주고도 사기 어려운\n교육',
    textPc: '을 배우면서\n현직 프로다운 소통 방식을 배울 수 있었습니다',
    textM: '을 배우면서 현직 프로다운\n소통 방식을 배울 수 있었습니다.',
    author: '앱 iOS 5기 수료생, 최OO',
  },
  {
    boldPc: '혼자 공부할 때는 전혀 알 수 없었던 부분',
    boldM: '혼자 공부할 때는 전혀 알 수 없던 부분',
    textPc: '을\n프로젝트를 통해 ‘협업’하며 원하는 꿈을 이룰 수 있었습니다.',
    textM: '을\n프로젝트를 통해 ‘협업’하며\n원하는 꿈을 이룰 수 있었습니다.',
    author: '프론트엔드 10기 수료생, 김OO',
  },
]

export const portfolioSlideList = [
  {
    imgUrl: 'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/portfolio_slide_img1.gif',
    title: '도시락',
    desc: '프론트엔드 8기 이OO, 반OO, 정OO',
  },
  {
    imgUrl: 'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/portfolio_slide_img2.gif',
    title: 'Coldplay',
    desc: '백엔드 : Python 9기',
  },
  {
    imgUrl: 'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/portfolio_slide_img3.gif',
    title: '우연히',
    desc: '앱 AOS 2기 채OO, 박OO, 황OO, 임OO, 정OO',
  },
  {
    imgUrl: 'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/portfolio_slide_img4.gif',
    title: '현실도피자',
    desc: '앱 iOS 2기 여OO, 김OO, 박OO, 장OO',
  },
  {
    imgUrl: 'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/portfolio_slide_img5.gif',
    title: '이커머스 분석',
    desc: '데이터 분석 1기 홍OO',
  },
  {
    imgUrl: 'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/portfolio_slide_img6.gif',
    title: '최종 포트폴리오',
    desc: 'UX/UI디자인 2기 최OO',
  },
  {
    imgUrl: 'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/portfolio_slide_img7.gif',
    title: '모아',
    desc: '앱 iOS 6기 김OO, 김OO, 김OO, 이OO, 여OO, 정OO',
  },
  {
    imgUrl: 'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/portfolio_slide_img8.gif',
    title: '포레',
    desc: '프론트엔드 3기 김OO, 배OO, 장OO, 조OO',
  },
  {
    imgUrl: 'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/portfolio_slide_img9.gif',
    title: '다온길',
    desc: '앱 AOS 2기 강OO,정OO,민OO,이OO,은OO,이OO',
  },
]

export const toolLogoList = [
  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/section3_li_3rd_tool_logo1.png',
  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/section3_li_3rd_tool_logo2.png',
  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/section3_li_3rd_tool_logo3.png',
  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/section3_li_3rd_tool_logo4.png',
  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/section3_li_3rd_tool_logo5.png',
  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/section3_li_3rd_tool_logo6.png',
  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/section3_li_3rd_tool_logo7.png',
  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/section3_li_3rd_tool_logo8.png',
  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/section3_li_3rd_tool_logo9.png',
  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/section3_li_3rd_tool_logo10.png',
]

export const firstCompanyList = [
  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/yeogi_logo.png',
  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/krafton_logo.png',
  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/3dot3_logo.png',
  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/linkagelab_logo.png',
  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/payhere_logo.png',
  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/opensg_logo.png',
  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/lg_logo.png',
]
export const secondCompanyList = [
  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/pengtai_logo.png',
  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/knowhow_logo.png',
  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/explatform_logo.png',
  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/lisn_logo.png',
  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/tmaxsoft_logo.png',
  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/doodlin_logo.png',
  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/moneylab_logo.png',
  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/bizbee_logo.png',
]

interface IVideoCardProps {
  imgUrl: string
  hashtag: string
  title: string
  videoUrl: string
  onClick: () => void
}

export const videoList: Omit<IVideoCardProps, 'onClick'>[] = [
  {
    imgUrl: 'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/youtube_thumbnail_1.png',
    title: '채용연계 인턴십으로 국내 최고\n에이전시에 UX 디자이너로 취업',
    hashtag: '#커리어전환 #UX리서치 #UX/UI디자인',
    videoUrl: 'https://www.youtube.com/embed/Ge2iPtYkSU0?si=t1bTW_Zxglkkqbnd',
  },
  {
    imgUrl:
      'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/youtube_thumbnail_2_new.png',
    title: '전공자가 아니여도 취업 가능한\n실무 트렌드를 반영한 단계별 커리큘럼',
    hashtag: '#비전공자 #프론트엔드개발 #커리어시작점',
    videoUrl: 'https://www.youtube.com/embed/5KCzC0Wfsng?si=I3RmGZYiCo_e8pYs',
  },
  {
    imgUrl: 'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/youtube_thumbnail_3.png',
    title: '비전공자도 게임 개발이 가능한\n수준높은 커리큘럼',
    hashtag: '#비전공자 #게임개발 #유니티게임개발',
    videoUrl: 'https://www.youtube.com/embed/FK_4VeaMIcg?si=AekWivCXl3kZGYcA',
  },
]

export const decoReview1SlideList = [
  {
    text: '데이터 분석의 기초부터 실습까지 경험해볼 수 있는 좋은 기회였습니다. 저는 이 훈련과정을 데이터 분석의 "첫걸음"이라고 생각합니다. 수강생중에 비전공자도 많았지만 모두 이해하기 쉽도록 처음부터 이론을 설명해주셔서 좋았습니다. 세번의 프로젝트는 배웠던 이론을 실습으로 더 탄탄하게 잡아주어 좋았습니다.',
    author: '데이터분석 수강생',
  },
  {
    text: '비전공자인데 가능할까? 생각했던 것들이 수업을 하루하루 들으면서 옆에 꾸준히 지켜봐주시는 강사님들, 저보다도 걱정을 더 많이 해주시고 응원해주시는 멘토님들, 항상 따뜻하게 맞이해주시는 매니저님들. 5개월 간 많은 것을 배웠고 많은 것을 느꼈습니다.',
    author: '프론트엔드 수강생',
  },
  {
    text: 'Java와 Kotlin의 이해도를 높인다는 저의 목표에 확실히 달성할 수 있었고, 팀 프로젝트를 통해 APP 개발에 대해 많은 것을 배우며 성장할 수 있었습니다. 또한 다양하고도 필요했던 특강(이력서, UI) 등을 통해 지식의 폭을 넓힐 수 있다는 점이 만족스러웠습니다.',
    author: '앱 AOS 수강생',
  },
  {
    text: '프론트엔드 개발에 대한 기초지식이 거의 없는 상태에서 수업을 들었는데 수료 후 리액트로 프로젝트 결과를 낼 만큼 성장할 수 있었습니다. 좋은 강사진과 좋은 운영 매니저님들 덕분에 개발적으로 멘탈적으로 많은 도움을 받으며 진행할 수 있었고, 동기와도 서로 돕는 분위기와 화목한 분위기가 조성되어 좋았습니다.',
    author: '프론트엔드 수강생',
  },
  {
    text: 'Python에 대해 배웠고, 백엔드에 관심을 두고 있지만 어떻게 공부를 해야하고 어떻게 취업 준비를 해야할 지 모르는 분, 그리고 AI에 도전해보고 싶고 서비스의 기획부터 배포까지 진행해보고 싶은 분, 팀 프로젝트 경험이 많이 없어 경험을 쌓고 싶으신 분, Django 개발에 관심이 있으신 분들께 강력히 추천합니다. 백엔드 부트캠프에서는 이러한 궁금증 및 질문에 명쾌한 해답과 개발 경험을 제공해주고 있으며, 팀 단위 개발을 통해 다른 사람들과의 교류 및 소통을 통해 협업하는 방식을 배울 수 있습니다. 또한 특강 등을 통해 취업과 관련된 정보 역시 제공하고 있기 때문에 향후 진로에 대해 고민중인 분들께도 강력히 추천합니다.',
    author: '백엔드 Python 수강생',
  },
]

export const decoReview2SlideList = [
  {
    text: '돈을 내고 다니는 다른 학원들보다 강의 퀄리티가 좋고 더 많은 자료들을 공유해주셨습니다. 우선 강사진들이 현직 필드에서 실무를 뛰고 계시며 유명하신 분들로만 이루어져있고 매달 특강을 진행해주시며 더 다양한 실무자들의 이야기를 들을 수 있었습니다. 매달 특강을 진행해주시며 더 다양한 실무자들의 이야기를 들을 수 있었습니다. 그리고 프로젝트가 끝나면 PT를 하며 실무자로 이루어진 평가단들이 와 피드백을 진행해주셔서 커리큘럼을 한번도 벗어나지 않고 체계적으로 수업이 진행되어 좋았습니다.',
    author: 'UX/UI 디자인 수강생',
  },
  {
    text: '기초, 개념, 프로젝트까지 체계적인 커리큘럼으로 구성되어 있어 학습, 포트폴리오에 큰 도움이 되었습니다! 이력서, 코테 특강들도 마련해주셔서 필요한 전체 과정을 폭넓게 경험할 수 있었습니다. 전공자라서 안드로이드 지식이 어느정도 있었지만 취업을 하기에는 포폴이 많아 미흡하신 분 또는 협업 경험이 부족하신 분께 강력 추천 드립니다.',
    author: '앱 AOS 수강생',
  },
  {
    text: '할 수 있다는 자신감이 생겼습니다. 앱 개발자가 되고 싶어 하드적인 스킬을 전혀 몰랐던 상황이였는데 부트캠프를 듣고 강사님, 멘토님들을 통해 많은 걸 배웠고 직접 프로젝트 팀원들과 협업하여 앱을 만들면서 정말 많이 성장할 수 있었고 앱스쿨을 수강하지 않았더라면 안드로이드 개발자로서 지금만큼 성장할 수 없었을 것 같습니다.',
    author: '앱 AOS 수강생',
  },
  {
    text: '데이터 분석의 기초부터 실습까지 경험해볼 수 있는 좋은 기회였습니다. 저는 이 훈련과정을 데이터 분석의 "첫걸음"이라고 생각합니다. 수강생중에 비전공자도 많았지만 모두 이해하기 쉽도록 처음부터 이론을 설명해주셔서 좋았습니다 ! 세번의 프로젝트는 배웠던 이론을 실습으로 더 탄탄하게 잡아주어 좋았습니다 ! 이번 훈련과정을 바탕으로 데이터 분석에 대한 공부를 더해 취업에 성공할 것 입니다!',
    author: '데이터 분석 수강생',
  },
  {
    text: '강사님의 강의력도 좋으셨고, 무엇보다 프로젝트 피드백을 친절하고 이해하기 쉽게 해주셔서 도움이 많이 되었습니다. 멘토님께서도 열정이 가득하셔서 수강생분들의 질문을 현직자의 입장에서 자세히 설명해주시고 좋은 조언도 많이 해주셨습니다. 공부도 하면서 이를 바탕으로 프로젝트를 진행하는 것이 정말 도움이 많이 되었습니다. 좋은 기회를 주셔서 정말 감사합니다.',
    author: '데이터 분석 수강생',
  },
]

interface IFaqList {
  question: string
  answer: string
}

export const faqList: IFaqList[] = [
  {
    question: '훈련장려금은 어떻게 지급되나요?',
    answer:
      '훈련장려금을 받기 위해서는 단위 기간 내 출석률이 80% 이상이어야 하며 훈련장려금은 훈련기관이 속한 지역의 고용센터에서 훈련생이 등록해 놓은 계좌를 통해 지급됩니다.',
  },
  {
    question: '직장 또는 학업과 병행이 가능할까요?',
    answer:
      '교육 기간 동안 주 5일, 매일 약 8시간의 교육 과정에 온전히 참여하실 수 있다면 원칙 상 수강할 수 있으나, 직장과 병행해야 해서 정해진 시간에 출석할 수 없고 하루 8시간의 학습 시간을 온전히 참여하기 어려운 경우 선발에서 제외됨을 알려드립니다.',
  },
  {
    question: '지원자격은 어떻게 되나요?',
    answer:
      '국민내일배움카드를 보유하고 있거나 발급 가능하다면 누구든지 지원하실 수 있습니다. 발급 가능 여부는 고용노동부 1350 또는 거주지 관할 고용센터에서 직접 확인해주셔야 합니다. (단, 미성년자의 경우 참여가 어렵다는 점을 안내드립니다.)',
  },
  {
    question: '국민취업지원제도와 병행할 수 있나요?',
    answer:
      'K-Digital 교육과 국민취업제도를 병행할 수 있다고 알려져 있으나, 특정 조건이 있을 수 있습니다.',
  },
  {
    question: '현재 내일배움카드가 없는데 신청이 가능할까요?',
    answer:
      '지원 당시 내일배움카드가 없어도 지원 가능합니다만 최종 합격발표 전까지는 실물 카드를 소지하고 있으셔야 합니다. 카드 발급 완료까지 일주일 이상 소요되기 때문에 고용노동부 1350을 통해 카드 발급 자격 확인 및 발급 신청을 빠르게 하셔야 합니다. (참가신청서 작성 시 실물 카드 확인 예정)',
  },
  {
    question: '교육비는 전액 지원되나요?',
    answer:
      'K-Digital 교육비는 디지털 분야의 인재 양성을 위한 프로그램으로 내일배움카드 발급 대상자에 한하여 교육비가 전액 지원됩니다, 다만 최근 5년 이내 KDT 수강이력이 있거나 불가한 상황이 있을 수 있으니 관할 고용센터에서 지원 가능한 상황인지 확인 부탁드리겠습니다.',
  },
  {
    question: '교육 과정은 온라인/오프라인 중 어떤 방식으로 진행되나요?',
    answer:
      '교육은 오프라인이 아닌, 온라인 라이브로 진행되며 국내에서, 거주 지역에 무관하게 학습할 수 있으나 KDT 프로그램의 특성상, 해외에서 수강하는 것은 불가능합니다. 교육 참여 중에 해외로 이동하는 것 역시 불가능합니다.',
  },
  {
    question: '수료 후 취업 지원은 어떤 방식으로 이루어지나요?',
    answer:
      '수료 이후에도 지속적인 구직활동 현황을 체크하며, 수료 후 6개월 간 이력서 및 포트폴리오 첨삭을 지원합니다. 취업 지원 전문가의 1:1 상담 프로그램도 운영하며 IT 전직무 수료생이 모인 ”세렝게티“ 네트워크를 통해 스터디, 프로젝트, IT 취업정보를 공유해드리고 있습니다.',
  },
  {
    question: '이미 국비지원을 받은 적이 있는 경우 재참여가 가능한가요?',
    answer:
      '국비지원 교육을 받았다고 하여 K-Digital 훈련참여가 불가하지는 않습니다. 다만 최근 5년 이내 K-Digital 수강이력이 있거나 상황에 따라 불가 할 수 있으니 관할 고용센터에서 확인 부탁드리겠습니다.',
  },
]

export const KDT_KLASS_TAB_LIST = [
  { label: '모집중', type: 'apply' },
  { label: '모집예정', type: 'early' },
  { label: '전체보기', type: 'all' },
]
